/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import themeColors from './ThemeColors.json';
import ModalCloseButton from '../assets/svg/ModalCloseButton';

const CookieWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 20;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 17px 24px 32px 24px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    justify-content: center;
  }

  &.is-hidden {
    display: none;
  }
`;

const CookieText = styled.p`
  color: ${themeColors.lavender};
  font-size: 0.75rem;
  line-height: 17px;
  font-family: 'Circular Spotify Text';
  width: 85%;
  max-width: 37.5rem;
  font-weight: 300;

  @media (min-width: 768px) {
    width: 75%;
    margin-right: 30px;
  }

  a {
    color: ${themeColors.lavender};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CookieBar = ({ themeId, cookieBar }) => {
  const [cookiesAccepted, setcookiesAccepted] = useState(!!Cookies.get('cookiesAccepted'));

  const text = cookieBar ?? 'We and <a href="https://www.spotify.com/legal/cookies-vendor-list/" target="_blank" rel="noopener noreferrer" data-event-label="our partners cookiebar">our partners</a> use cookies to personalize your experience, to show you ads based on your interests, and for measurement and analytics purposes. By using our website and our services, you agree to our use of cookies as described in our <a href="https://www.spotify.com/legal/cookies-policy/" target="_blank" rel="noopener noreferrer" data-event-label="our policy cookiebar">cookie policy.</a>';

  const updateCookies = event => {
    event.preventDefault();
    Cookies.set('cookiesAccepted', true, { expires: 365 });
    setcookiesAccepted(true);
  };

  return (
    <CookieWrapper
      className={`cookierBar_anim ${cookiesAccepted && 'is-hidden'}`}
      themeId={themeId}
    >
      <CookieText dangerouslySetInnerHTML={{ __html: text }} />
      <ModalCloseButton
        style={{
          width: 25,
          height: 25,
        }}
        fill={themeColors.lavender}
        onClick={updateCookies}
      />
    </CookieWrapper>
  );
};

export default CookieBar;
