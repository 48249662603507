/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

function SvgComponent(props) {
  const { fill, stroke } = props;
  return (
    <svg width={32} height={32} {...props} viewBox="0 0 32 32" fill="none">
      <circle cx="16" cy="16" r="15" fill={fill || '#fff'} />
      <path d="M17.8284 23.0195V13.1795H15.1684V23.0195H17.8284ZM14.8484 9.95953C14.8484 10.8395 15.5884 11.5795 16.4884 11.5795C17.4084 11.5795 18.1284 10.8395 18.1284 9.95953C18.1284 9.03953 17.4084 8.29953 16.4884 8.29953C15.5884 8.29953 14.8484 9.03953 14.8484 9.95953Z" fill={stroke || '#fff'} />
    </svg>
  );
}

SvgComponent.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: '#fff',
  stroke: '#fff',
};

export default SvgComponent;
