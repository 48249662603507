/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Sine, TweenMax } from 'gsap';

const LookWrapper = styled.div`
  height: 100%;
  width: 87.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 95%;
    height: 100%;
    margin: 0px;
  }
`;

const Title = styled.h1`
  font-size: 49px;
  font-weight: 700;
  line-height: 53.9px;
  margin: -2.5rem 0px 10px;

  @media only screen and (min-width: 768px) {
    font-size: 4.125rem;
    line-height: 4.5375rem;
    margin: -3.125rem 0px 1.25rem;
  }
`;

const Paragraph = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  max-width: 30.9375rem;
  padding: 0px 5px;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0px 1.875rem;
  }
`;

const Look = ({ slide, cms, device }) => {
  useEffect(() => {
    if (slide == 3 && device !== 'mobile') {
      TweenMax.staggerTo(
        '.stagger',
        0.35,
        {
          y: 0,
          ease: Sine.easeInOut,
          opacity: 1,
        },
        0.1,
      );
    }
  }, [slide, device]);

  return (
    <LookWrapper className="look">
      <Title className={device !== 'mobile' ? 'stagger' : ''}>{cms.payoff.look.heading}</Title>
      <Paragraph
        className={device !== 'mobile' ? 'stagger' : ''}
        dangerouslySetInnerHTML={{ __html: cms.payoff.look.copy }}
      />
    </LookWrapper>
  );
};

Look.propTypes = { cms: PropTypes.objectOf(PropTypes.any).isRequired };

export default Look;
