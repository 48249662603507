/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable object-shorthand */
import React from 'react';
import { TweenMax, Power2 } from 'gsap';
import Cookies from 'js-cookie';
import isValidEmail from '../../utils/emailCheck';
import Api from '../../utils/api';
import {
  ConnectWrapper,
  Title,
  Text,
  InputTitle,
  InputFlex,
  Input,
  ConnectButton,
  EmailInput,
  ErrorMessage,
} from '../../styles/connectStyles';

import Footer from '../Footer';
import Loader from '../Loader';

class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      errors: {
        firstName: '',
        email: '',
      },
      emailIsValid: false,
    };

    this.connectToSpotify = this.connectToSpotify.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /* Remove Cookie */
    Cookies.remove('firstName');
    Cookies.remove('lastName');
    const elems = [
      ...document.querySelectorAll('.slide_in'),
      document.querySelector('.button_slide'),
    ];

    /* Trigger Animations */
    elems.map((elem, i) => {
      TweenMax.to(elem, 0.5, {
        y: 0,
        ease: Power2.easeOut,
        opacity: elem.classList.contains('slide_in') ? 1 : 0.25,
        delay: 0.4 + i * 0.1,
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this

  handleChange() {
    const newState = {
      firstName: this.firstInput.value.replace(/\s/g, ''),
      lastName: this.lastInput.value.replace(/\s/g, ''),
      errors: {
        firstName: '',
        email: '',
      },
    };

    newState.email = this.emailInput.value;

    if (isValidEmail(newState.email)) {
      newState.emailIsValid = true;
    } else {
      newState.emailIsValid = false;
    }
    this.setState({ ...newState });

    /* Allow button click if inpout fields are true */
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      await this.formValidation();

      this.connectToSpotify();
    } catch (errors) {
      const errorState = {};

      errors.forEach(error => {
        errorState[error] = true;
      });

      this.setState({ errors: errorState });
    }
  }

  formValidation() {
    const errors = [];

    return new Promise((resolve, reject) => {
      if (!isValidEmail(this.state.email)) {
        errors.push('email');
      }

      if (this.firstInput.value.length <= 2) {
        errors.push('firstName');
      }

      if (errors.length) {
        reject(errors);
        console.log(errors);
      } else {
        resolve();
      }
    });
  }

  connectToSpotify() {
    const { route, history } = this.props;

    const { slug } = route;

    /* Set Cookie for payoff */
    Cookies.set('firstName', this.state.firstName);
    Cookies.set('lastName', this.state.lastName);

    const connect = new Api({ url: 'connect' });

    /* Set connect params */
    const params = {
      slug: slug,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email.trim(),
    };

    if (process.env.NODE_ENV === 'development') {
      params.env = 'http://localhost:3000/';
    }

    connect
      .submit(params)
      .then(response => {
        if (response) {
          window.location.href = response.data.url;
        }
      })
      .catch(error => {
        console.log(error);
        history.push(`/${slug}/connect`);
      });
  }

  render() {
    const { firstName, lastName, email, errors, emailIsValid } = this.state;
    const { globalState } = this.props;
    const { cms, event } = globalState;
    const buttonDisabled = !firstName.length || !lastName.length || !email.length || !emailIsValid;

    return cms && event ? (
      <ConnectWrapper themeId={Object.keys(event).length ? event.themeId : 1} className="connect">
        <Title className="slide_in">{cms.welcome.header}</Title>
        <Text className="slide_in">{cms.welcome.copy}</Text>
        <form onChange={this.handleChange} onSubmit={this.handleSubmit}>
          <div className="slide_in">
            <InputFlex>
              <div className="add_margin">
                <label>
                  <InputTitle>{cms.welcome.form.firstName.label}</InputTitle>
                  {errors.firstName && (
                    <ErrorMessage>{cms.welcome.form.firstName.error}</ErrorMessage>
                  )}
                  <Input
                    className={firstName && 'add_opacity'}
                    placeholder={cms.welcome.form.firstName.placeholder}
                    name="firstname"
                    type="text"
                    ref={node => (this.firstInput = node)}
                  />
                </label>
              </div>
              <div className="add_margin">
                <label>
                  <InputTitle>{cms.welcome.form.lastName.label}</InputTitle>
                  <Input
                    className={lastName && 'add_opacity'}
                    placeholder={cms.welcome.form.lastName.placeholder}
                    name="lastname"
                    type="text"
                    ref={node => (this.lastInput = node)}
                  />
                </label>
              </div>
            </InputFlex>
            <EmailInput>
              <label>
                <InputTitle>{cms.welcome.form.email.label}</InputTitle>
                {errors.email && <ErrorMessage>{cms.welcome.form.email.error}</ErrorMessage>}
                <Input
                  className={email && 'add_opacity'}
                  placeholder={cms.welcome.form.email.placeholder}
                  name="email"
                  type="text"
                  ref={node => (this.emailInput = node)}
                />
              </label>
            </EmailInput>
          </div>
          <div className="connect-button__container">
            <ConnectButton
              type="submit"
              disabled={buttonDisabled}
              themeId={Object.keys(event).length ? event.themeId : 1}
              id="connect_button"
              className="button_slide"
              data-event-label="connect button"
            >
              {cms.welcome.form.button}
            </ConnectButton>
          </div>
          <p className="connect-footnote slide_in">{cms.welcome.anonymised}</p>
        </form>
        <Footer globalState={globalState} cmsContent={cms} />
      </ConnectWrapper>
    ) : (
      <Loader />
    );
  }
}

export default Connect;
