import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MicroModal from 'micromodal';
import themeColors from './ThemeColors.json';

/* SVG */
import SpotifySvg from '../assets/svg/SpotifySvg';
import HelpSvg from '../assets/svg/HelpSvg';

const HeaderWrapper = styled.header`
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 15;
  width: calc(100% - 2.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    top: 1.5625rem;
    left: 2.25rem;
    width: calc(100% - 4.5rem);
  }

  & button {
    background: none;
    border: none;
    cursor: pointer;
  }

  & svg.spotify-logo {
    height: 33px;
    width: 213px;

    @media only screen and (min-width: 768px) {
      height: 2.4375rem;
      width: 15.625rem;
    }
  }

  & svg.help-icon {
    height: 32px;
    width: 32px;
    cursor: pointer;
    user-select: none;
  }
`;

const openModal = () => {
  MicroModal.show('modal-1');
};

const Header = ({ globalState }) => globalState.activeResultsSlide === 0 && (
  <HeaderWrapper>
    {
      window.location.pathname === '/404' ? (
        <SpotifySvg
          className="spotify-logo"
          fill="#fff"
        />
      ) : (
        <>
          <SpotifySvg
            className="spotify-logo"
            fill={
              !globalState.spotifyConnect.token
              ? themeColors.lavender
              : themeColors[globalState.event.themeId || 'lavender']
            }
          />
          <button type="button" onClick={openModal}>
            <HelpSvg
              className="help-icon"
              stroke={
                !globalState.spotifyConnect.token
                ? themeColors[globalState.event.themeId || 'lavender']
                : themeColors.lavender
              }
              fill={
                !globalState.spotifyConnect.token
                ? themeColors.lavender
                : themeColors[globalState.event.themeId || 'lavender']
              }
            />
          </button>
        </>
      )
    }
  </HeaderWrapper>
);

Header.propTypes = { globalState: PropTypes.objectOf(PropTypes.any).isRequired };

export default Header;
