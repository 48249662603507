/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import { TweenMax, Sine } from 'gsap';
import Cookies from 'js-cookie';
import Api from '../../utils/api';

/* Components */
import Loader from '../Loader';
import Thanks from './resultsComponents/Thanks';
import Look from './resultsComponents/Look';
import UserInfo from './resultsComponents/UserInfo';
import TopList from './resultsComponents/TopList';
import Footer from '../Footer';

/* Styled */
import { Details, InfoTitle, Genre, ProfileImage } from '../../styles/resultsStatsStyles';
import themeColors from '../ThemeColors.json';

/* SVG */
import ProfileSvg from '../../assets/svg/ProfileSvg';

const ResultsWrapper = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: ${themeColors.lavender};

  .swiper-container {
    height: 100%;
  }

  .swiper-wrapper {
    height: 100%;
    width: 100vw;

    .swiper-pagination * :focus {
      outline: none;
    }
  }

  .stagger {
    opacity: 0;
  }

  @media only screen and (min-width: 768px) {
    height: 100%;
    flex-direction: row;
    min-height: 35.5rem;
    justify-content: center;

    .stagger {
      transform: translateY(1.875rem);
    }
  }
`;

const Slide = styled.div`
  height: 100%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(odd) {
    background-color: ${themeColors.lavender};

    & h1, h2, h3, h4, h5, p {
      color: ${props => themeColors[props.themeId]};
    }
  }

  &:nth-child(even) {
    background-color: ${props => themeColors[props.themeId]};
    
    & h1, h2, h3, h4, h5, p {
      color: ${themeColors.lavender};
    }
  }

  .horizontal {
    @media screen and (max-height: 500px) and (max-width: 1023px) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
`;

const Results = props => {
  const [connectToken, setConnectToken] = useState(false);
  const [slide, setSlide] = useState(0);
  const { globalState } = props;
  const { cms } = globalState;
  const { updateGlobalState } = props;
  const { slug } = props.route;
  const [device, setDevice] = useState(null);
  const firstName = Cookies.get('firstName');
  const lastName = Cookies.get('lastName');

  const checkDevice = () => {
    let currentDevice;

    if (window.innerWidth < 768) {
      // mobile
      currentDevice = 'mobile';
    } else if (window.innerWidth < 1024) {
      // tablet
      currentDevice = 'tablet';
    } else {
      // desktop
      currentDevice = 'desktop';
    }

    if (device !== currentDevice) {
      setDevice(currentDevice);
    }
  };

  const getCmsContent = () => {
    const activationSlug = window.location.pathname.split('/')[1];
    const submitSlug = new Api({ url: 'cms' });

    submitSlug
      .submit({ table: activationSlug })
      .then(res => {
        updateGlobalState({ cms: res.data.content, event: res.data.event });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('error:', err);
      });
  };

  const getUserInfo = () => {
    const request = new Api({
      url: 'users/analyser',
      key: globalState.spotifyConnect.token,
    });

    const params = {
      type: 'all',
      slug,
    };

    request.submit(params).then(response => {
      updateGlobalState({
        info: {
          topTracks: response.data.user.topTrack,
          topArtist: response.data.user.topArtist,
          topGenre: response.data.user.topGenre,
          img: response.data.user.image,
        },
      });

      TweenMax.to('.loader', 0.3, {
        opacity: 0,
        ease: Sine.easeInOut,
        onComplete: () => setConnectToken(true),
      });
    });
  };

  const initSlider = () => {
    const swiper = new Swiper('.swiper-container', {
      direction: 'vertical',
      speed: 600,
      mousewheel: { sensitivity: 2 },
      effect: 'fade',
      fadeEffect: { crossFade: true },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      navigation: { nextEl: '.next' },
      touchRatio: 0.2,
      resistanceRatio: 0.85,
      initialSlide: slide,
    });

    swiper.on('slideNextTransitionEnd', () => {
      setSlide(swiper.activeIndex);
    });

    swiper.on('slidePrevTransitionEnd', () => {
      setSlide(swiper.activeIndex);
    });

    swiper.on('slideChange', () => {
      updateGlobalState({
        activeResultsSlide: swiper.activeIndex,
        totalSlides: swiper.slides.length,
      });
    });

    window.addEventListener('keydown', e => {
      if (e.keyCode === 40) {
        swiper.slideNext();
      } else if (e.keyCode === 38) {
        swiper.slidePrev();
      }
    });
  };

  useEffect(() => {
    checkDevice();
    getCmsContent();
    window.addEventListener('resize', checkDevice);
  }, []);

  useEffect(() => {
    /* Allow token globalState to be set then request user analysis */
    if (globalState.spotifyConnect.token) {
      getUserInfo();
    } else {
      window.location.pathname = `/${slug}/connect`;
    }
  }, [globalState.spotifyConnect.token]);

  useEffect(() => {
    if (connectToken) {
      initSlider();

      TweenMax.to('.swiper-container', 0.3, {
        opacity: 1,
        ease: Sine.easeInOut,
      });
    }
  }, [connectToken, device]);

  useEffect(() => {
    if (connectToken && cms) {
      updateGlobalState({ loading: false });
    } else {
      updateGlobalState({ loading: true });
    }
  }, [connectToken, cms]);

  return (
    <ResultsWrapper>
      {connectToken && cms ? (
        <div className="swiper-container">
          <div className="swiper-wrapper">

            <Slide themeId={globalState.event.themeId} className="swiper-slide">
              <Thanks globalState={globalState} slide={slide} cms={cms} device={device} />
            </Slide>

            {device === 'mobile' ? (
              Object.keys(globalState.info.topGenre).length && (
                <Slide themeId={globalState.event.themeId} className="swiper-slide">
                  <div className="horizontal">
                    <Details className="fade-up set-fade">
                      <ProfileImage image={globalState.info.img}>
                        {!globalState.info.img && <ProfileSvg className="profile-image" />}
                      </ProfileImage>
                      <InfoTitle className="name">{`${firstName} ${lastName}`}</InfoTitle>
                    </Details>
                    <Genre>
                      <div>
                        <InfoTitle style={{ fontSize: 24, fontWeight: 900 }} className="other fade-up set-fade">
                          {cms.payoff.topList.topGenre}
                        </InfoTitle>
                        <h2
                          className="set-fade second-fade"
                          style={{
                            fontSize: 18,
                            fontFamily: 'Circular Spotify Head',
                            fontWeight: 700,
                            marginTop: 15,
                          }}
                        >
                          {globalState.info.topGenre && globalState.info.topGenre.genre}
                        </h2>
                      </div>
                    </Genre>
                  </div>
                </Slide>
              )
            ) : (
              Object.keys(globalState.info.topGenre).length && (
                <Slide themeId={globalState.event.themeId} className="swiper-slide">
                  <UserInfo globalState={globalState} slide={slide} device={device} />
                </Slide>
              )
            )}

            {
              Object.keys(globalState.info.topArtist).length
              && Object.keys(globalState.info.topTracks).length
              && (
                <Slide themeId={globalState.event.themeId} className="swiper-slide">
                  <TopList globalState={globalState} slide={slide} device={device} />
                </Slide>
              )
            }

            <Slide themeId={globalState.event.themeId} className="swiper-slide">
              <Look slide={slide} cms={cms} device={device} />
            </Slide>
          </div>
          <div className="swiper-pagination" />
        </div>
      ) : (
        <Loader />
      )}
      {Object.keys(cms).length ? <Footer globalState={globalState} cmsContent={cms} /> : ''}
    </ResultsWrapper>
  );
};

export default Results;
