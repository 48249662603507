import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import themeColors from './ThemeColors.json';
import LoaderAnimation from '../assets/animation/loader-animation.json';

const Loader = styled.div`
  position: absolute;
  top: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${themeColors.lavender};
  z-index: 9999;
`;

const LoaderWrapper = styled.div`
  width: 400px;
  height: auto;
  display: inline-block;
`;

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  return (
    <Loader>
      <LoaderWrapper>
        <Lottie options={defaultOptions} useSubFrames={false} />
      </LoaderWrapper>
    </Loader>
  );
};

export default Loading;
