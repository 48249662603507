/* eslint-disable react/no-deprecated */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { matchRoutes } from 'react-router-config';
import qs from 'query-string';
import { Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Api from './api';

class Validation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { canRender: false };

    this.validateRoute = this.validateRoute.bind(this);
    this.updateSpotifyConnect = this.updateSpotifyConnect.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    // get query object from URL
    const query = qs.parse(location.search);

    // if we have the connect token in the URL
    if (query && query.token) {
      this.updateSpotifyConnect();
    } else {
      // validate on first load
      this.checkUserAuth();
    }
  }

  checkUserAuth() {
    const { updateGlobalState, globalState, history } = this.props;
    const activationSlug = window.location.pathname.split('/')[1];

    if (!activationSlug && Cookies.get('slug') !== 'undefined') {
      window.location.replace(window.location.href + Cookies.get('slug'));
    }

    let authState = { ...globalState.userAuth };

    if (activationSlug && !globalState.userAuth.loggedIn) {
      const submitSlug = new Api({ url: 'cms' });

      submitSlug
        .submit({ table: activationSlug })
        .then(res => {
          const isAuthenticated = 'data' in res;
          // const { awardsType } = res.data;
          if (!isAuthenticated) {
            history.push('/404');
            this.setState({ canRender: true });
          } else {
            Cookies.set('slug', activationSlug, { expires: 1 / 24 });

            authState = {
              loggedIn: true,
              slugAuthenticated: isAuthenticated,
            };
            updateGlobalState(
              {
                userAuth: authState,
                awardsType: 'activation',
                cms: res.data.content,
                event: res.data.event,
              },
              this.validateRoute,
            );
          }
        })
        .catch(() => {
          console.error('Auth expired');
          // reset authState if the Auth has expired
          authState = {
            loggedIn: false,
            slugAuthenticated: false,
          };
        });
    } else {
      this.validateRoute();
    }
  }

  updateSpotifyConnect() {
    const { history, updateGlobalState, location } = this.props;
    const activationSlug = window.location.pathname.split('/')[1];
    const query = qs.parse(location.search);
    const { token } = query;

    const connectState = {
      status: 'success',
      token,
    };

    const redirect = () => {
      history.push(`/${activationSlug}/results`);
      this.setState({ canRender: true });
    };

    updateGlobalState({ spotifyConnect: connectState }, redirect);
  }

  validateRoute() {
    const { history, routes } = this.props;

    // creates an array of route matches
    const branch = matchRoutes(routes, window.location.pathname);

    if (branch.length) {
      // get the target route object (get last matched route)
      let route = branch[branch.length - 1].route;

      // check if we've matched a subroute container
      if (route.routes) {
        // redirect to first subroute
        route = route.routes[0];
        history.push(route.path);

        this.setState({ canRender: true });

        // return false to ensure we don't send panel view event
        return false;
      }

      this.setState({ canRender: true });
    } else {
      this.setState({ canRender: true });
      history.push('/404');
    }
  }

  render() {
    const { children } = this.props;
    const { canRender } = this.state;

    return <Route render={() => canRender && children} />;
  }
}

export default Validation;
