import React from 'react';
import styled from 'styled-components';
import themeColors from '../ThemeColors.json';

const FourOFourWrapper = styled.div`
  height: 100%;
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fefefe;
  background-color: ${themeColors.lavender};
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 39.6px;
  margin-bottom: 1rem;

  @media only screen and (min-width: 768px) {
    font-size: 4.125rem;
    line-height: 4.5375rem;
  }
`;

const Text = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
`;

const FourOFour = () => (
  <FourOFourWrapper>
    <Title>How did you get here?</Title>
    <Text>It looks like you’re in the wrong place unfortunately!</Text>
  </FourOFourWrapper>
);

export default FourOFour;
