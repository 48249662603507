const initialState = {
  spotifyConnect: {
    status: 'idle',
    token: null,
  },
  awardsType: '',
  userAuth: {
    loggedIn: false,
    slugAuthenticated: null,
  },
  info: {
    topTrack: null,
    topArtist: null,
    topGenre: null,
    img: null,
  },
  cms: {},
  event: {},
  activeResultsSlide: 0,
  totalSlides: 0,
  loading: false,
};

export default initialState;
