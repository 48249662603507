/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Sine, TweenMax } from 'gsap';

const TopListWrapper = styled.div`
  height: 100%;
  width: 87.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 95%;
    height: 100%;
    margin: 0px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 640px;
  margin: 15px auto;

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
    text-align: left;
    flex-direction: row;
    margin: 50px auto;
  }
`;

const ListItemImage = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin: 10px auto 15px;

  @media only screen and (min-width: 768px) {
    width: 158px;
    height: 158px;
    margin-right: 63px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const ListMobileHeading = styled.h3`
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
  font-family: 'Circular Spotify Head';
  margin-bottom: 10px;
  display: none;

  @media only screen and (max-width: 768px) {
    display: unset;
  }
`;

const ListItemCopy = styled.div`
  & h3 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 900;
    font-family: 'Circular Spotify Head';
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  & h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'Circular Spotify Text';

    @media only screen and (min-width: 768px) {
      font-size: 49px;
      line-height: 54px;
      font-weight: 900;
      font-family: 'Circular Spotify Head';
    }
  }
`;

const TopList = ({ slide, device, globalState }) => {
  useEffect(() => {
    if (slide == 2 && device !== 'mobile') {
      TweenMax.staggerTo(
        '.stagger',
        0.35,
        {
          y: 0,
          ease: Sine.easeInOut,
          opacity: 1,
        },
        0.1,
      );
    }
  }, [slide, device]);

  return (
    <TopListWrapper className="">
      {(slide == 2 || device === 'mobile') && (
        <React.Fragment>
          <ListItem>
            <ListMobileHeading className="stagger">{globalState.cms.payoff.topList && globalState.cms.payoff.topList.topTrack}</ListMobileHeading>
            <ListItemImage className="stagger" src={globalState.info.topTracks.image} alt="" />
            <ListItemCopy>
              <h3 className="stagger">{globalState.cms.payoff.topList && globalState.cms.payoff.topList.topTrack}</h3>
              <h2 className="stagger">{globalState.info.topTracks.name}</h2>
              <h4 className="stagger">
                {
                  globalState.info.topTracks.artists.map((artist, index) => (
                    `${artist.name}${index < globalState.info.topTracks.artists.length - 1 ? ', ' : ''}`
                  ))
                }
              </h4>
            </ListItemCopy>
          </ListItem>

          <ListItem>
            <ListMobileHeading className="stagger">{globalState.cms.payoff.topList && globalState.cms.payoff.topList.topArtist}</ListMobileHeading>
            <ListItemImage className="stagger" src={globalState.info.topArtist.image} alt="" />
            <ListItemCopy>
              <h3 className="stagger">{globalState.cms.payoff.topList && globalState.cms.payoff.topList.topArtist}</h3>
              <h2 className="stagger">{globalState.info.topArtist.name}</h2>
            </ListItemCopy>
          </ListItem>
        </React.Fragment>
      )}
    </TopListWrapper>
  );
};

TopList.propTypes = { globalState: PropTypes.objectOf(PropTypes.any).isRequired };

export default TopList;
