/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from './components/App';
import closestParent from './utils/closestParent';

// Styles
import './styles/index.scss';

document.addEventListener('DOMContentLoaded', () => {
  // function to send the GA event
  const sendGaEvent = event => {
    const element = closestParent(event.target, '[data-event-label]', true);
    if (element) {
      const eventLabel = element.getAttribute('data-event-label');
      global.ga('send', {
        hitType: 'event',
        eventCategory: window.location.pathname,
        eventAction: 'click',
        eventLabel,
      });
    }
  };
  document.addEventListener('click', sendGaEvent, false);

  // watch for global errors
  window.onerror = (msg, url, line, col, error) => {
    global.Raven.captureException(error);
  };
});

ReactDOM.render(
  <AppContainer>
    <App />
  </AppContainer>,
  document.getElementById('root'),
);
