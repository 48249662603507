/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import { TweenMax, Sine } from 'gsap';

/* SVG */
import arrow from '../../../assets/svg/slider-arrow.svg';

const ThanksWrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;

  @media only screen and (min-width: 768px) {
    min-height: 29.0625rem;
    margin: 0px;
    height: 100vh;
  }

  .next {
    position: absolute;
    bottom: 28px;
  }

  .next:focus,
  .right:focus {
    outline: none;
  }
`;

const Title = styled.h1`
  font-size: 49px;
  font-weight: 700;
  line-height: 53.9px;
  margin: -2.5rem 0 10px;

  @media only screen and (min-width: 768px) {
    font-size: 66px;
    line-height: 70px;
    margin: 0 0 1.25rem;
  }
`;

const Paragraph = styled.p`
  width: 100%;
  max-width: 320px;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: 24.0625rem;
    max-width: none;
  }
`;

const Arrow = styled(Svg)`
  cursor: pointer;
  transform: rotate(90deg);
  margin: 75px auto -75px;
  width: 2.625rem;
  height: 2.625rem;

  @media only screen and (min-width: 768px) {
    position: static;
    margin: 3rem 0 0;
    width: auto;
  }
`;

const Thanks = ({ globalState, slide, cms, device }) => {
  useEffect(() => {
    if (slide == 0 && device !== 'mobile') {
      TweenMax.staggerTo(
        '.stagger',
        0.35,
        {
          y: 0,
          ease: Sine.easeInOut,
          opacity: 1,
        },
        0.1,
      );
    } else if (device === 'mobile') {
      TweenMax.to('.stagger', 0.35, {
        ease: Sine.easeInOut,
        opacity: 1,
        delay: 0.35,
      });
    }
  }, [slide, device]);

  return (
    <ThanksWrapper>
      {(slide == 0 || device === 'mobile') && (
        <React.Fragment>
          <Title className="stagger">{cms.payoff.thanks.header}</Title>
          {
            Object.keys(globalState.info.topArtist).length
            && Object.keys(globalState.info.topGenre).length
            && Object.keys(globalState.info.topTracks).length
            ? (
              <Paragraph
                className="stagger"
                dangerouslySetInnerHTML={{ __html: cms.payoff.thanks.copy }}
              />
            ) : ('')
          }
          <div className="next stagger">
            <Arrow src={arrow} className="right" />
          </div>
        </React.Fragment>
      )}
    </ThanksWrapper>
  );
};

Thanks.propTypes = { cms: PropTypes.objectOf(PropTypes.any).isRequired };

export default Thanks;
