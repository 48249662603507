/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-mutable-exports */
// for react-router-config

import Stages from './components/routes/index';
import Connect from './components/routes/Connect';
import Results from './components/routes/Results';
import FourOFour from './components/routes/404';

export let routes = [];

const buildRouteConfig = (cmsContent, slug) => {
  routes = [
    {
      path: '/',
      component: FourOFour,
      exact: true,
    },
    {
      path: '/404',
      component: FourOFour,
    },
    {
      path: `/${slug}/results`,
      exact: true,
      component: Results,
      requiresSpotifyConnect: true,
      slug,
      cms: cmsContent,
    },
    {
      path: `/${slug}/`,
      component: Stages,
      routes: [
        {
          path: `/${slug}/connect`,
          component: Connect,
          exact: true,
          slug,
          cms: cmsContent,
        },
      ],
    },
  ];

  return routes;
};

export default buildRouteConfig;
