/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import themeColors from './ThemeColors.json';

/* SVG */
import SpotifySvg from '../assets/svg/SpotifySvg';

const FooterWrapper = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 15;
  padding: 0 34px 40px 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 0 20px 20px 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  & svg.spotify-logo {
    width: 170px;

    @media only screen and (min-width: 768px) {
      height: 2.4375rem;
      width: 15.625rem;
    }
  }
`;

const FooterLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  li {
    margin: 0 0.3125rem;
    padding: 0.1875rem 0.5rem;
  }
`;

const Link = styled.a`
  color: ${props => props.linkColor};
  text-decoration: none;
  font-size: 0.75rem;

  :hover {
    text-decoration: underline;
  }
`;

const Footer = ({ globalState, cmsContent }) => {
  const links = [
    {
      link: cmsContent.footer.legal.url,
      label: cmsContent.footer.legal.label,
      eventLabel: 'footer legal',
    },
    {
      link: cmsContent.footer.privacy.url,
      label: cmsContent.footer.privacy.label,
      eventLabel: 'footer privacy',
    },
    {
      link: cmsContent.footer.cookies.url,
      label: cmsContent.footer.cookies.label,
      eventLabel: 'footer cookies',
    },
    {
      link: cmsContent.footer.revoke.url,
      label: cmsContent.footer.revoke.label,
      eventLabel: 'footer revoke',
    },
  ];

  return globalState.activeResultsSlide === globalState.totalSlides - 1 && (
    <FooterWrapper>
      <SpotifySvg
        className="spotify-logo"
        fill={themeColors.lavender}
      />
      <FooterLinks>
        {links.map(link => (
          <li key={link.eventLabel}>
            <Link
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              data-event-label={link.eventLabel}
              linkColor={themeColors.lavender}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </FooterLinks>
    </FooterWrapper>
  );
};

export default Footer;
