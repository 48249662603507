import styled from 'styled-components';

const connectColors = {
  1: '#144235',
  2: '#538EE9',
  3: '#943586',
  5: '#4744F5',
  6: '#F9419F',
  7: '#156E4B',
  8: '#F64733',
  9: '#000000',
  10: '#FF8EB6',
  11: '#000000',
  12: '#fae62d',
  13: '#cdf564',
  14: '#ffcdd2',
  15: '#9bf0e1',
  lavender: '#734D85',
};

const ConnectWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  z-index: 10;
  min-height: 35.5rem;
  background-color: ${props => connectColors[props.themeId]};

  .slide_in {
    opacity: 0;
    transform: translate(0, 1.5rem);
  }
  .connect-footnote {
    font-size: 12px;
    margin: 40px auto 0 auto;
    font-family: 'Circular Spotify Text';
  }

  h1,
  h2,
  p,
  input {
    color: ${connectColors.lavender};
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 39.6px;

  @media only screen and (min-width: 768px) {
    font-size: 4.125rem;
    line-height: 4.5375rem;
  }

  @media only screen and (max-width: 640px) {
    margin-top: 70px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  width: 280px;
  margin: 0.25rem 0px 2rem;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    width: 26.25rem;
  }
`;

const InputTitle = styled.h2`
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 2.5rem;
  text-transform: uppercase;
  text-align: left;
`;

const InputFlex = styled.div`
  margin: 0px;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 1.5625rem;

    .add_margin {
      margin: 0px 0.625rem;
    }
  }
`;

const Input = styled.input`
  background: transparent;
  border: 0px;
  font-family: 'Circular Spotify Text';
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.875rem;
  width: 100%;
  padding: 0px 0.5rem 0.3125rem 0;
  border-bottom: 0.0625rem solid ${connectColors.lavender};
  margin: 0 0 1rem;
  opacity: 0.3;
  transition: all 0.2s ease-in;
  border-radius: 0px;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-family: 'Circular Spotify Text';
    color: ${connectColors.lavender};
  }

  &.add_opacity {
    opacity: 1;
  }
`;

const ConnectButton = styled.button`
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border-radius: 6.25rem;
  min-width: 13.75rem;
  height: 2.9375rem;
  border: 0px;
  margin: 2rem 0px 0px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background: ${connectColors.lavender};
  color: ${props => connectColors[props.themeId]};
  transform: translateY(1.5rem);
  opacity: 0;
  cursor: pointer;
  padding: 15px;
  line-height: 1;

  &[disabled] {
    opacity: 0;
    cursor: not-allowed;
  }
  &:not([disabled]) {
    opacity: 1 !important;
  }
  :focus {
    outline: none;
  }

  @media only screen and (min-width: 768px) {
    font-size: 1.125rem;
    height: 2.9375rem;
    width: 16.25rem;
    margin-top: 3.5rem;
  }

  &.add_button_styles {
    cursor: pointer;
    opacity: 1 !important;

    :hover {
      background: transparent;
      color: #ffffff;
    }
  }
`;

const EmailInput = styled.div`
  width: 100%;
  text-align: left;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    padding: 0 10px;
  }

  & input {
    width: 100%;
  }
`;

const ErrorMessage = styled.h4`
  color: #ff0000;
`;

export {
  ConnectWrapper,
  Title,
  Text,
  InputTitle,
  InputFlex,
  Input,
  ConnectButton,
  ErrorMessage,
  EmailInput,
};
