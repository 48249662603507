/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components';
// import Svg from 'react-inlinesvg';

const profileCss = css`
  height: 72px;
  height: 72px;
  margin-bottom: 0.3125rem;

  @media only screen and (min-width: 768px) {
    height: 60%;
    width: 60%;
  }
`;

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .set-fade {
    opacity: 0;
    transform: translateY(0);
  }

  @media only screen and (min-width: 768px) {
    height: 55%;
    min-height: 29.0625rem;

    .set-fade {
      /* opacity: 0; */
      transform: translateY(1.875rem);
    }
  }
`;

const UserInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: relative;

  div {
    text-align: center;

    @media only screen and (min-width: 768px) {
      text-align: left;
    }
  }

  @media screen and (max-height: 500px) and (max-width: 1023px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  @media only screen and (min-width: 768px) {
    align-items: flex-start;
    height: 100%;
    width: 80%;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 60rem;
    max-height: 29.0625rem;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -3.125rem;
  }
`;

const InfoTitle = styled.h3`
  color: #fefefefe;
  font-size: 2.0625rem;
  font-weight: 900;
  line-height: 2.375rem;
  text-transform: capitalize;

  @media only screen and (min-width: 768px) {
    &.name {
      margin-bottom: 0.5rem;
      font-size: 2.25rem;
      line-height: 2.25rem;
    }

    &.other {
      font-size: 1.5rem;
      line-height: 1.5375rem;
      margin-bottom: 0.375rem;
    }
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  .name {
    margin-bottom: 10px;
    z-index: 5;
    font-family: "circular Spotify Head";
  }

  @media only screen and (min-width: 768px) {
    justify-content: center;
    flex-direction: row;
    width: auto;
    height: auto;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

const fadedColors = {
  1: '#144235',
  2: '#1821BB',
  3: '#FFC864',
  4: '#12382D',
  5: '#4100F5',
  6: '#F573A0',
  7: '#19E68C',
  8: '#FF4632',
  9: '#B49BC8',
  10: '#F472A0',
  11: '#ffc864',
};

const FadedBackground = styled.div`
  position: absolute;
  width: 90vw;
  height: 14.375rem;
  background: ${props => `${fadedColors[props.themeId]}`};
  opacity: 0.45;
  z-index: -1;
  left: -120vw;
  /* top: 0.9375rem; */
  top: -2.1875rem;
  min-width: 1200px;
  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;
  }

  @media only screen and (min-width: 1200px) {
    min-width: 1350px;
  }
`;

const ProfileImage = styled.div`
  background: ${({ image, themeId }) =>
    image ? `url(${image}) no-repeat center center / cover` : `${fadedColors[themeId]}`};
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  margin: 0 1.25rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 0;

  & .profile-image {
    ${profileCss}
  }
  @media only screen and (max-width: 767px) {
    order: 1;
    margin: 0 auto 0rem;
    width: 132px;
    height: 132px;
  }
`;

const StatImage = styled.div`
  position: absolute;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  border-radius: 100%;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 28px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(0);

  @media only screen and (min-width: 768px) {
    transform: translateY(1.875rem);
    bottom: 40px;
    top: auto;
    margin: 0 auto;
  }

  @media only screen and (min-width: 1024px) {
    height: 17.625rem;
    width: 17.625rem;
    bottom: 0px;
    top: 0px;
    left: auto;
    right: -120px;
    margin: auto 0;

    &.track-img {
      margin-left: 0px;
      right: -60px;
    }
  }

  @media only screen and (min-width: 1200px) {
    right: -12.5rem;

    &.track-img {
      right: -7.5rem;
    }
  }
`;

const Track = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 33.75rem;
  order: 2;

  h5 {
    margin: 130px 0px 3px;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 300;
  }

  @media only screen and (min-width: 768px) {
    display: block;
    height: auto;
    border-left: 0.0625rem solid #fefefe;
    padding-left: 0.9375rem;
    margin-top: 120px;
    order: 0;

    h3 {
      margin-top: 0px;
    }

    h5 {
      margin: 0px;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 37%;
    margin-top: 0px;

    h5 {
      max-width: 10.25rem;
    }
  }
`;

const TrackFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 7px;

  div {
    text-align: center;
    color: #fefefe;

    p {
      font-size: 1.125rem;
      line-height: 1.35rem;
      font-weight: 300;
    }
  }

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
    margin-left: 0px;
    margin-bottom: 0px;

    div {
      text-align: left;
    }
  }
`;

// const PlayButton = styled(Svg)`
//   border: 0.0625rem solid #fefefe;
//   border-radius: 50%;
//   margin-right: 0.75rem;
//   min-height: 2.25rem;
//   min-width: 2.25rem;
//   cursor: pointer;
// `;

const Artist = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  order: 2;

  @media screen and (max-width: 767px) and (min-height: 667px) {
    margin-top: 30px;
  }

  .text {
    color: #fefefe;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    margin: 135px 0 0px;
  }

  h3 {
    margin-bottom: -8px;
  }

  @media only screen and (min-width: 768px) {
    margin: 120px auto 30px;
    height: auto;

    .text {
      font-size: 2.25rem;
      line-height: 2.325rem;
      margin: 0px;
    }

    h3 {
      margin-bottom: 0px;
    }

    .add-border {
      padding-left: 0.9375rem;
      border-left: 0.0625rem solid #fefefe;
    }
  }

  @media only screen and (min-width: 1024px) {
    order: 3;
    margin: 0px;
    text-align: left;

    .text {
      max-width: 15.25rem;
    }
  }
`;

const Genre = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 33.75rem;
  order: 1;
  margin-top: 50px;
  text-align: center;

  h2 {
    font-size: 31px;
    font-weight: 300;
    color: #fefefe;
    text-transform: capitalize;
  }

  h3 {
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 768px) {
    height: auto;
    justify-content: flex-end;
    order: 2;
    margin: 0 0 0 auto;

    div {
      border-left: 0.0625rem solid #fefefe;
      padding-left: 0.9375rem;
    }

    h2 {
      margin-right: 3.21875rem;
      font-size: 2.25rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: auto;
    justify-content: flex-start;
    order: 1;
    width: 23%;
    transform: translateY(12.375rem);
    margin: 0;
  }
`;

export {
  StatsWrapper,
  UserInfo,
  FadedBackground,
  Details,
  ProfileImage,
  InfoTitle,
  Track,
  TrackFlex,
  StatImage,
  Artist,
  Genre,
};
