/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { TransitionGroup, Transition } from 'react-transition-group';
import Div100vh from 'react-div-100vh';

// Config
import cmsContent from '../cmsContent.json';
import Validation from '../utils/validation';
import buildRouteConfig from '../routeConfig';
import initialState from '../utils/initialState';

// Components
import Modal from './Modal';
import CookieBar from './Cookiebar';
import Header from './Header';

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  opacity: 0;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.updateGlobalState = this.updateGlobalState.bind(this);
    this.resetGlobalState = this.resetGlobalState.bind(this);
  }

  componentDidMount() {
    this.setState({ slug: window.location.pathname.slice(1) });
  }

  resetGlobalState(callback) {
    const newState = { ...initialState };
    this.updateGlobalState(newState, callback);
  }

  updateGlobalState(newState, callback) {
    if (process.env.NODE_ENV === 'development') {
      const newStateKeys = Object.keys(newState);

      for (let i = 0; i < newStateKeys.length; i++) {
        const key = newStateKeys[i];
        if (newStateKeys.indexOf(key) !== -1) {
          // eslint-disable-next-line no-console
          console.warn(
            `You are updating the globalState with a new key. Please ensure to add all new keys to the initialState object\nkey: ${key}`,
          );
        }
      }
    }

    const state = { ...this.state, ...newState };
    // eslint-disable-next-line no-console

    this.setState(state, () => {
      // use callback function to run anything that relies on the newly set state
      callback && callback();
    });
  }

  render() {
    const passableProps = {
      globalState: this.state,
      updateGlobalState: this.updateGlobalState,
      resetGlobalState: this.resetGlobalState,
    };
    const slug = window.location.pathname.split('/')[1];
    const routes = buildRouteConfig(cmsContent, slug);
    const cookieBar = Object.keys(this.state.cms).length ? this.state.cms.footer.cookies.bar : null;
    return (
      <Router>
        <Route
          render={props => (
            <Validation {...props} {...passableProps} routes={routes} cmsContent={cmsContent}>
              <Div100vh>
                <Container
                  className="background_fade"
                  themeId={Object.keys(this.state.event).length ? this.state.event.themeId : 1}
                >
                  <CookieBar
                    themeId={Object.keys(this.state.event).length ? this.state.event.themeId : 1}
                    cookieBar={cookieBar}
                  />
                  {!passableProps.globalState.loading
                  && <Header globalState={passableProps.globalState} />}
                  <TransitionGroup component={null} exit={false}>
                    <Transition key={props.location.key} unmountOnExit timeout={100}>
                      {() => <React.Fragment>{renderRoutes(routes, passableProps)}</React.Fragment>}
                    </Transition>
                  </TransitionGroup>
                </Container>
              </Div100vh>
            </Validation>
          )}
        />
        <Modal globalState={passableProps.globalState} />
      </Router>
    );
  }
}

export default App;
