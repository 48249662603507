/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

function SvgComponent(props) {
  const { fill } = props;
  return (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33" fill="none">
      <circle cx="16.5" cy="16.5" r="15.5" transform="rotate(-90 16.5 16.5)" stroke={fill || '#fff'} strokeWidth="2" />
      <path d="M12 12.5147L20.4853 21" stroke={fill || '#fff'} strokeLinecap="square" />
      <path d="M20.4853 12.5147L12 21" stroke={fill || '#fff'} strokeLinecap="square" />
    </svg>
  );
}

SvgComponent.propTypes = { fill: PropTypes.string.isRequired };

export default SvgComponent;
