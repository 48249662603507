import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import themeColors from './ThemeColors.json';
import ModalCloseButton from '../assets/svg/ModalCloseButton';

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${themeColors.lavender};
  width: 100%;
  max-width: 768px;
  color: ${props => themeColors[props.themeId]};
  padding: 68px 84px;
  box-sizing: border-box;
  box-shadow: 0 0 0 100vw rgba(25, 20, 20, 0.1);

  & h2, p, a {
    color: ${props => themeColors[props.themeId]};
  }

  @media (max-width: 768px) {
    padding: 68px 30px 40px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ModalContent = styled.div`
  position: relative;

  & h2 {
    font-size: 57px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }

  & li {
    font-size: 36px;
    line-height: 69px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 40px;
    }

    a {
      text-decoration: none;
    }
  }
`;

const ModalClose = styled.button`
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
  background: none;
  border: none;

  & svg {
    pointer-events: none;
  }
`;

const Modal = ({ globalState }) => {
  const { cms } = globalState;

  return (
    <ModalWrapper themeId={globalState.event.themeId} className="modal-1" id="modal-1" aria-hidden="true">
      <ModalClose
        type="button"
        aria-label="Close modal"
        data-micromodal-close
      >
        <ModalCloseButton fill={themeColors[globalState.event.themeId || 'lavender']} />
      </ModalClose>
      <div tabIndex="-1" data-micromodal-close>
        <div role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <ModalContent>
            {cms.info && <h2>{cms.info.label}</h2>}
            {cms.footer && (
              <ul>
                <li>
                  <a
                    href={cms.footer.legal.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cms.footer.legal.label}
                  </a>
                </li>
                <li>
                  <a
                    href={cms.footer.privacy.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cms.footer.privacy.label}
                  </a>
                </li>
                <li>
                  <a
                    href={cms.footer.cookies.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cms.footer.cookies.label}
                  </a>
                </li>
                <li>
                  <a
                    href={cms.footer.revoke.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cms.footer.revoke.label}
                  </a>
                </li>
              </ul>
            )}
          </ModalContent>
        </div>
      </div>
    </ModalWrapper>
  );
};

Modal.propTypes = { globalState: PropTypes.objectOf(PropTypes.any).isRequired };

export default Modal;
