/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export default class Stages extends React.Component {
  render() {
    const { updateGlobalState, globalState } = this.props;
    const { routes } = this.props.route;

    const SubRoutes = route => (
      <Route
        path={route.path}
        render={props => (
          <route.component
            {...props}
            route={route}
            updateGlobalState={updateGlobalState}
            globalState={globalState}
          />
        )}
      />
    );

    return routes.map((route, i) => <SubRoutes key={i} {...route} />);
  }
}

Stages.propTypes = {
  updateGlobalState: PropTypes.func.isRequired,
  globalState: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.objectOf(PropTypes.any).isRequired,
};
