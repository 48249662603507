/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Sine, TweenMax } from 'gsap';
import Cookies from 'js-cookie';
import { Details } from '../../../styles/resultsStatsStyles';
import ProfileSvg from '../../../assets/svg/ProfileSvg';
import themeColors from '../../ThemeColors.json';

const UserInfoWrapper = styled.div`
  height: 100%;
  width: 87.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fefefe;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 95%;
    height: 100%;
    margin: 0px;
  }
`;

const DetailsBox = styled.div`
  width: 289px;
  min-height: 289px;
  max-width: 400px;
  background-color: ${themeColors.lavender};
  padding: 25px 30px;
  text-align: left;
  margin-left: 48px;

  & h2 {
    font-size: 49px;
    line-height: 54px;
    font-family: 'Circular Spotify Head';
    font-weight: 900;
    color: ${props => themeColors[props.themeId]} !important;
  }

  & h3 {
    font-size: 26px;
    line-height: 28px;
    font-family: 'Circular Spotify Head';
    font-weight: 900;
    margin-bottom: 15px;
    color: ${props => themeColors[props.themeId]} !important;
  }
`;

const DetailsProfile = styled.div`
  min-width: 289px;
  max-width: 400px;
  height: 289px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
`;

const DetailsProfileName = styled.div`
  font-size: 49px;
  line-height: 54px;
  font-family: 'Circular Spotify Head';
  font-weight: 900;
  margin-bottom: 42px !important;
  color: ${themeColors.lavender} !important;
`;

const DetailsProfileImage = styled.div`
  width: 158px;
  height: 158px;
  border-radius: 50%;
  object-fit: contain;
`;

const ProfileImg = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin: 10px auto 15px;

  @media only screen and (min-width: 768px) {
    width: 158px;
    height: 158px;
    margin-right: 63px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const UserInfo = ({ slide, device, globalState }) => {
  const firstName = Cookies.get('firstName');
  const lastName = Cookies.get('lastName');
  useEffect(() => {
    if (slide == 1 && device !== 'mobile') {
      TweenMax.staggerTo(
        '.stagger',
        0.35,
        {
          y: 0,
          ease: Sine.easeInOut,
          opacity: 1,
        },
        0.1,
      );
    }
  }, [slide, device]);

  return (
    <UserInfoWrapper className="">
      {(slide == 1 || device === 'mobile') && (
        <React.Fragment>
          <Details className="fade-up set-fade">
            <DetailsProfile>
              <DetailsProfileName className="name stagger">{`${firstName} ${lastName}`}</DetailsProfileName>
              <DetailsProfileImage className="stagger" image={globalState.info.img} themeId={globalState.event.themeId}>
                {
                  globalState.info.img ? (
                    <ProfileImg src={globalState.info.img} alt="profile" />
                  ) : (
                    <ProfileSvg fill={themeColors.lavender} stroke={themeColors[globalState.event.themeId]} className="profile-image" />
                  )
                }
              </DetailsProfileImage>
            </DetailsProfile>
            <DetailsBox className="stagger" themeId={globalState.event.themeId}>
              <h3 className="stagger">{globalState.cms.payoff.userInfo.topGenre}</h3>
              <h2 className="stagger">{globalState.info.topGenre.genre}</h2>
            </DetailsBox>
          </Details>
        </React.Fragment>
      )}
    </UserInfoWrapper>
  );
};

UserInfo.propTypes = { globalState: PropTypes.objectOf(PropTypes.any).isRequired };

export default UserInfo;
